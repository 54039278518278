<template>
  <div>
    <load-profile />
    <!-- Table Container Card -->
    <b-card>
      <b-row>
        <b-col
          cols="12"
          class="text-right"
        >
          <b-button
            v-if="$can('update', 'player')"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="primary"
            class="mb-1"
            :to="{ name: 'update-player', params: { id: $route.params.id } }"
          >
            <feather-icon icon="EditIcon" />
            {{ $t("button.update") }}
          </b-button>
          <b-button
            v-if="$can('create', 'player')"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="primary"
            class="mb-1 ml-1"
            :to="{ name: 'create-player', query: { id: $route.params.id } }"
          >
            <feather-icon icon="CopyIcon" />
            {{ $t("button.clone") }}
          </b-button>
          <b-button
            v-if="
              data.parentId === userId &&
                ($can('create', 'player') || $can('edit', 'player'))
            "
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="primary"
            class="mb-1 ml-1"
            @click="showDepositOrWithdrawModel"
          >
            <feather-icon icon="CreditCardIcon" />
            {{ $t("button.deposit") }}
          </b-button>
        </b-col>
      </b-row>
      <n-input-view
        v-model="data"
        :fields="fields"
        :init-value="initData"
      />
      <b-row>
        <b-col
          cols="12"
          class="text-right"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            class="mt-1"
            @click="back"
          >
            {{ $t("button.back") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <deposit-or-withdraw
      ref="depositWithdrawModel"
    />
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
} from 'bootstrap-vue'
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonDelete from '@/components/NButtonDelete'
import NFormConfirmation from '@/components/NFormConfirmation'
import NInputView from '@/components/NInputView'
import DepositOrWithdraw from '@/views/account/depositOrWithdraw'
import FormInput from './formInput'
import VendorFormInput from './vendorFormInput'

const PlayerRepository = Repository.get('player')

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonDelete,
    NFormConfirmation,
    NInputView,
    DepositOrWithdraw,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        lastName: null,
        phone: null,
        username: null,
        password: null,
        minBetTime: 0,
        maxBetTime: 0,
        maxBetGame: 0,
        maxDrawBetGame: 0,
        maxWinDay: 0,
        maxBetAmountParley: 0,
        ccy: null,
        isEnable: true,
        isReadonly: false,
      },
      initData: {},
      loading: false,
      fields: [],
    }
  },
  computed: {
    userData() {
      return this.$store.state.profile.name
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem('userData'))
    },
    userId() {
      if (this.$store.state.profile.id) {
        return this.$store.state.profile.id
      }

      return this.userData?.id
    },
  },
  mounted() {
    this.setInputField()
    this.show()
  },
  methods: {
    setInputField() {
      if (this.initData.isVendor) {
        this.fields = VendorFormInput
      } else {
        this.fields = FormInput.map(value => {
          if (value.key === 'password' || value.key === 'retypePassword') {
            value.rules = value.rules.replace('required|', '')
            value.rules = value.rules.replace('required', '')
          } else if (value.key === 'username') {
            value.disabled = true
          }

          return value
        })
      }
    },
    show() {
      PlayerRepository.show(this.$route.params.id).then(response => {
        if (response?.data?.data) {
          const { data } = response.data
          this.initData = {
            ...data,
          }
          this.setInputField()
        }
      })
    },
    showDepositOrWithdrawModel(username) {
      this.$refs.depositWithdrawModel.changeData({
        type: 'deposit',
        username: this.data.username,
        amount: 0,
        userId: this.$route.params.id,
        remark: null,
      })

      this.$refs.depositWithdrawModel.show(
        `${this.$t('field.deposit')} (${username})`,
      )
    },
    deleteItem() {
      PlayerRepository.delete(this.$route.params.id)
        .then(response => {
          this.back()
        })
        .catch(error => {})
        .then(() => {
          this.loading = false
        })
    },
    back() {
      this.$router.back()
    },
  },
  setup() {
  },
}
</script>
